import { useState, useEffect } from "react";

const listeners = [];
let idCounter = 0;

// Add new alert to alert queue:
export const addAlert = (message, type = "info", duration = 5000) => {
  const id = idCounter++;
  const alert = { id, message, type, duration };
  listeners.forEach((l) => {
    l({ action: "add", alert });
    setTimeout(() => removeAlert(id), duration);
  });
};

export const removeAlert = (id) => {
  listeners.forEach((l) => {
    l({ action: "remove", id });
  });
};

export const useAlertManager = () => {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    const listener = (e) => {
      switch (e.action) {
        case "add":
          setAlerts((prev) => [...prev, e.alert]);
          break;
        case "remove":
          setAlerts((prev) => prev.filter((a) => a.id !== e.id));
          break;
        default:
          break;
      }
    };

    listeners.push(listener);

    return () => {
      const idx = listeners.indexOf(listener);
      if (idx > -1) {
        listeners.splice(idx, 1);
      }
    };
  }, []);

  return alerts;
};
