import React from "react";

import { useAlertManager, removeAlert } from "./AlertManager";

const AlertContainer = () => {
  const alerts = useAlertManager();
  return (
    <div className="alert-container">
      {alerts.map((a) => {
        return (
          <div key={a.id} className={`alert alert-${a.type}`}>
            <div
              className="grid-container halves-15-1"
              style={{ padding: "0px" }}
            >
              <span>{a.message}</span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <span
                  className="material-icons-outlined"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeAlert(a.id)}
                >
                  close
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AlertContainer;
